<template>
  <div>
    <div>
      <component
        :is="`${service}-view`"
        :service-type="service"
        :service-type-id="serviceTypeId"
        action="edit"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceTypeView",
  components: {
    MenuView: () => import("@/components/Services/Meal/Menu/ViewMenu"),
  },
  data() {
    return {};
  },
  computed: {
    service() {
      return this.$route.params.service;
    },
    serviceTypeId() {
      return this.$route.params.id;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
